import { CssBaseline } from "@material-ui/core";
import React from "react";

interface PrivacySection {
  section: string;
  content: string;
}

interface PrivacyProps {
  // If you have any specific props for this component, you can define them here
}

const Privacy: React.FC<PrivacyProps> = (props) => {
  const data: PrivacySection[] = [
    {
      section: "Information We Collect",
      content:
        "When you grant us access to the Instagram Graph API, we collect the following information: Insights data related to your Instagram account Information about your media (posts, stories, etc.)",
    },
    {
      section: "How We Use Your Information",
      content:
        "We use the collected information to analyze your Instagram activity and gain insights into your popularity on the platform. This allows us to provide you with targeted advertisements that align with your interests and popularity.",
    },
    {
      section: "Information Sharing and Disclosure",
      content:
        "We do not share or disclose your personal information with any third parties, except as necessary to fulfill our advertising services or as required by law.",
    },
    {
      section: "Security",
      content:
        "We take appropriate measures to protect your personal information and ensure its confidentiality and integrity.",
    },
    {
      section: "Contact Us",
      content:
        "If you have any questions or concerns about our privacy policy or how we handle your personal information, please contact us at arjun@dfftech.in.",
    },
  ];

  return (
    <div>
      <div className="container-fluid">
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand" href="https://dfftech.in/">
            <img
              src="https://www.dfftech.com/static/media/dfflogo.01604fc8.svg"
              width="140"
              height="80"
              className="d-inline-block align-top"
              alt=""
            />
          </a>
        </nav>
      </div>
      <div
        className="container"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <h1 className="text-center mt-5">Privacy Policy</h1>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <h4>{item.section}</h4>
            <p>{item.content}</p>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Privacy;
