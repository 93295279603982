import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from '../../../../www/assets/images/mobile-image.png';
import HomeSkillsComponent from './homepage-skills-strength';
import HomePageMenu from './homepage-header';
import SwipeDialog from './homepage-header-swipeimages';
import HomePageFooterComponent from './homepage-footer';

const backgroundShape = require('../../../images/shape.svg');
const backgroundImage = require('../../../../www/assets/images/clouds13.png');

class HomeHeadingComponent extends Component<any, any> {

  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  state = {
    learnMoredialog: false,
    getStartedDialog: false
  };

  componentDidMount() { }

  openDialog = (event: any) => {
    this.setState({ learnMoredialog: true });
  }

  dialogClose = (event: any) => {
    this.setState({ learnMoredialog: false });
  }

  openGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: true });
  }

  closeGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <HomePageMenu {...this.props} />
        {/* <div>
          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.headerGrid}>
              <Grid container item xs={12} >
                <Grid item xs={12}>
                  <div style={{ backgroundColor: 'white', height: 100, textAlign: 'center', }}>
                    <Grid container item xs={12} >
                      <Grid item xs={12}>

                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </div> */}

        <SwipeDialog open={true} />
        <div className={classes.root}>
          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12} >
                <Grid item xs={12} >

                  <div className={classes.sample}>
                    <Typography
                      variant="h3"
                      gutterBottom
                      className={classes.headerContent}
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false"
                    >
                      Your problem, Our solution
                    </Typography>

                    <div
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto', height: '6px', width: '400px', backgroundColor: '#b49b57', marginBottom: '60px', borderRadius: 4
                      }}>

                    </div>
                    {/* <div style={{ height: 6, width: 200, backgroundColor: '#4c9dd8', marginBottom: 72 }}></div> */}
                    <Typography
                      gutterBottom
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false"
                      className={classes.headerText}
                    >
                      We build an end to end Product for all businesses of any domain.
                      <br />For all your IT problems to run your business safe and secure,<br />
                      We Have a Solution at Dfftech.
                    </Typography>
                  </div>
                  {/* <div className={classes.borderLine}></div> */}
                </Grid>

              </Grid>
            </Grid>
          </Grid>


          <div className={classes.headerMiddleContent}>

            <Grid container direction="row" justify="center">

              <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid1} >
                <Grid item xs={2} lg={2} xl={2} md={2}>
                </Grid>

                <Grid item xs={10} lg={10} xl={10} md={10}>
                  <Typography style={{ color: 'black', fontFamily: "'Montserrat', sans-serif", fontSize: 19, fontWeight: 100 }} gutterBottom>
                    No blah...blah...blah.... What you can expect from DFFTech
                </Typography>
                  <Typography style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 17, fontWeight: 100 }} gutterBottom>
                    <br />We build <span style={{ color: 'rgb(76, 124, 216)' }}>Web</span>, <span style={{ color: 'rgb(76, 124, 216)' }}>Mobile</span> and <span style={{ color: 'rgb(76, 124, 216)' }}>Desktop</span> apps,
                      Intergrating With <span style={{ color: 'rgb(76, 124, 216)' }}>Data Analytics</span>, <br /><span style={{ color: 'rgb(76, 124, 216)' }}>Machine Learning</span> and <span style={{ color: 'rgb(76, 124, 216)' }}>Artificial Intelligence</span>.
                      </Typography>
                </Grid>

              </Grid>


              <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid1} >

                <Grid item xs={12} lg={5} md={6} className={classes.headerPointsHeight}>
                  <Typography className={classes.headerContentPoints} gutterBottom>
                    <span
                      data-aos="fade-right"
                      data-aos-duration="500"
                      data-aos-delay="0"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false">
                      UX/UI Design
                    </span>
                    <br />
                    <span
                      data-aos="fade-right"
                      data-aos-duration="500"
                      data-aos-delay="300"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false">
                      API Development
                    </span>
                    <br />
                    <span
                      data-aos="fade-right"
                      data-aos-duration="500"
                      data-aos-delay="600"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false">
                      Database Design
                    </span>
                    <br />
                    <span
                      data-aos="fade-right"
                      data-aos-duration="500"
                      data-aos-delay="900"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false">
                      DevOps
                    </span>
                  </Typography>
                </Grid>

                <Grid item xs={12} lg={7} md={6} className={classes.headerPointsBlah}>
                  <Typography style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 19, fontWeight: 100 }} gutterBottom>
                    Some blah...blah...blah...
                </Typography>
                  <Typography style={{ textAlign: 'justify', fontFamily: "'Montserrat', sans-serif", fontSize: 17, fontWeight: 100, color: 'rgb(0, 0, 0)' }} gutterBottom>
                    Design For Future Technologies, is an end to end Product development company based out of
                    Hyderabad, India. We are a team of designers and developers offering <span style={{ color: 'rgb(76, 124, 216)' }}>UX/UI design, API development, DataBase design.</span>
                    <br /> In Open Source Technologies world with vast number of technology stacks available with MIT licence,
                    we build the best solution for your Product considering technology stack, time, money,
                    scaling and promosing you we give you the best possible solution.<br /><br />
                  </Typography>

                  <Typography style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 17, fontWeight: 100, color: '#2a405a' }} gutterBottom>
                    And we do Strategic Partnership with other companies to design and build great products.
                  </Typography>

                </Grid>

              </Grid>
            </Grid>
          </div>










          {/* <div className={classes.backImage} style={{ marginTop: 30 }}>

            <Grid container direction="row" justify="center">
              <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                <Grid container item xs={12} >

                  <Grid item xs={12} lg={5} md={6}>
                    <div className={classes.sample}>
                      <div>
                        <img src={logo} />
                      </div>
                    </div>
                  </Grid>
                  <Grid lg={2} >
                  </Grid>
                  <Grid item xs={12} lg={5} md={6}>
                    <div className={classes.boxcontent}>


                      <Typography variant="headline" gutterBottom style={{ paddingTop: 60, lineHeight: 1.4, width: '80%', marginLeft: 'calc(100% - 90%)' }}>
                        In Open Source Technologies world with vast number of
                        technology stacks available with MIT licence, <br />we build the best solution
                        for your Product considering technology stack, time, money, scaling and promosing you we give you
                        the<br />
                        best possible solution.
                      </Typography>

                      <Typography variant="headline" gutterBottom style={{ paddingTop: 10, lineHeight: 1.4, width: '80%', marginLeft: 'calc(100% - 90%)' }}>
                        <br />We build <span style={{ color: 'rgb(76, 124, 216)' }}>Web</span>, <span style={{ color: 'rgb(76, 124, 216)' }}>Mobile</span> and <span style={{ color: 'rgb(76, 124, 216)' }}>Desktop</span> apps,
                      <br />Intergrating With
                      <br /><span style={{ color: 'rgb(76, 124, 216)' }}>Data Analytics</span>, <span style={{ color: 'rgb(76, 124, 216)' }}>Machine Learning</span> and <span style={{ color: 'rgb(76, 124, 216)' }}>Artificial Intelligence</span>.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div> */}

          <HomeSkillsComponent />

          <HomePageFooterComponent />

        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: 'rgb(235, 236, 226)',
    //marginTop: 60
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '75%',
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },

  grid1: {
    width: '75%',
    marginTop: 80,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)',
      marginTop: 24,
    },
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },
  marginbottom73: {
    marginBottom: 42,
    fontWeight: 600,
    color: '#2a405a',
    //borderBottom: `1px solid ${theme.palette.grey['A100']}`,
    // '&::after': {
    //   content: `''`,
    //   border: '15px solid #f2f2f6',
    //   display: 'inline-block',
    //   position: 'absolute',
    //   top: '-20px',
    //   bottom: '-20px',
    //   right: '-35%',
    //   width: '80%',
    //   zIndex: -1
    // },
    // '&::before': {
    //   content: `''`,
    //   border: '15px solid #f2f2f6',
    //   display: 'inline-block',
    //   position: 'absolute',
    //   top: '-20px',
    //   bottom: '-20px',
    //   right: '-35%',
    //   width: '80%',
    //   zIndex: -1
    // }
  },
  marginbottom72: {
    marginBottom: 72,
    fontWeight: 600,
    color: '#2a405a'
  },
  boxcontent: {
    width: 420,
    height: 720,
    backgroundColor: '#f2f9ff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  },
  backImage: {
    marginBottom: 40,
    background: `url(${backgroundImage}) no-repeat`,
    backgroundSize: 'contain'
  },
  headerGrid: {
    width: '75%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
  },
  borderLine: {
    height: 6,
    width: 200,
    backgroundColor: '#4c9dd8'
  },
  headerContent: {
    fontFamily: "'Pacifico'",
    fontSize: '52px',
    letterSpacing: '3px',
    marginBottom: 42,
    fontWeight: 500,
    color: '#2a405a',
    [theme.breakpoints.only('xs')]: {
      fontWeight: 200,
      fontSize: '44px',
    }
  },
  headerText: {
    fontSize: 28,
    fontWeight: 400,
    [theme.breakpoints.only('xs')]: {
      fontWeight: 200,
      fontSize: '24px',
    }
  },
  headerMiddleContent: {
    marginTop: 40,
    backgroundColor: 'rgb(235, 236, 226)',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0
    }
  },
  headerContentPoints: {
    textAlign: "end",
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 40,
    fontWeight: 200,
    marginLeft: 90,
    color: '#2a405a',
    [theme.breakpoints.only('xs')]: {
      fontSize: 32,
    }
  },
  headerPointsHeight: {
    height: 400,
    [theme.breakpoints.only('xs')]: {
      height: 320
    }
  },
  headerPointsBlah: {
    height: 400,
    [theme.breakpoints.only('xs')]: {
      height: 560
    }
  }
});

//export default HomeComponent;

export default withStyles(styles)(HomeHeadingComponent);
