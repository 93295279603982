import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from '../../../../www/assets/images/mobile-image.png';
import HomeWhatWeDoComponent from './homepage-whatwedo';

const backgroundShape = require('../../../images/shape.svg');
const opensource = require('../../../../www/assets/images/opensource.png')

class HomeSkillsComponent extends Component<any, any> {

  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  state = {
    learnMoredialog: false,
    getStartedDialog: false
  };

  componentDidMount() { }

  openDialog = (event: any) => {
    this.setState({ learnMoredialog: true });
  }

  dialogClose = (event: any) => {
    this.setState({ learnMoredialog: false });
  }

  openGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: true });
  }

  closeGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        {/* <Topbar /> */}
        <div className={classes.root}>
          <Grid container direction="row" justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
              <Grid container item xs={12} >

                <Grid item xs={12} lg={6} md={12}>
                  <div className={classes.sample}>
                    <Typography
                      variant="h3"
                      gutterBottom
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                      data-aos-once="false"
                      className={classes.skillsHeader}>
                      Our Skills | Our Strength
                    </Typography>

                    {/* <div style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto', height: '6px', width: '400px', backgroundColor: 'rgb(76, 124, 216)', marginBottom: '60px', borderRadius: 4
                    }}></div> */}
                  </div>
                  <div className={classes.boxcontent}>
                    <Typography
                      variant="h5" gutterBottom className={classes.skillsContent}>
                      <span
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false">
                        Got an IDEA ?
                      </span>

                      <br /><span
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="700"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false">
                        Our goal at 'Design For Future Technologies', is to turn you idea in to a beautiful and an awesome app.
                        </span>
                      <br />
                      <br />
                      <span
                        style={{ fontSize: '32px', color: '#1565C0' }}
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="700"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false">
                        We Love UI...
                        </span>
                      <br />
                      <br />
                      <span
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="700"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false">
                        We follow Pixel perfect UI design. We create apps using Angular, React, React Native, Node, Python, DotNet Core......
                      </span>

                    </Typography>
                    {/* <Typography variant="headline" gutterBottom style={{ paddingTop: 60, lineHeight: 1.4, width: '80%', marginLeft: 'calc(100% - 90%)' }}>
                      In world of open source <br />software, saas and innumerable
                        <br />technology stacks – we figure <br />out what is best for your
                        product <br />(cost wise, development wise, <br />scaling wise) and give
                        you the<br />
                      beta.
                      </Typography> */}
                  </div>
                </Grid>

                {/* <Grid lg={1} xl={1}>
                </Grid> */}

                <Grid item xs={12} lg={6} md={12} xl={6}>
                  <div>
                    <div>
                      {/* <img src={opensource} className={classes.skillsImage} /> */}
                    </div>
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>


          <HomeWhatWeDoComponent />
        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: 'rgb(235, 236, 226)',
    marginTop: 60,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30
    },
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '80%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: 42
    }
  },
  marginbottom72: {
    marginBottom: 72,
    fontWeight: 600
  },
  boxcontent: {
    //width: 420,
    //height: 160,
    // backgroundColor: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  },
  skillsImage: {
    width: '720px !important',
    [theme.breakpoints.up('md')]: {
      width: '80%'
    },
  },
  skillsHeader: {
    color: '#2a405a',
    marginBottom: 30,
    fontFamily: "'Maven Pro', cursive",
    fontSize: '48px',
    fontWeight: 500,
    letterSpacing: '0px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '32px',
    },
  },
  skillsContent: {
    paddingTop: 20,
    lineHeight: 1.5,
    width: '80%',
    color: '#2a405a',
    [theme.breakpoints.only('xs')]: {
      fontSize: '20px',
    },
  }
});

//export default HomeComponent;

export default withStyles(styles)(HomeSkillsComponent);
