import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Layout from "./layout";
import employeeProfile from "./components/viewcomponents/profile/employee-profile";
import dashboard from "./components/viewcomponents/dashboard/dashboard";
import Leaveapplication from "./components/viewcomponents/leave/leave";
import startsession from "./components/viewcomponents/employee/startsession";
import endsession from "./components/viewcomponents/employee1/endsession";
import LoginForm from "./components/viewcomponents/signin/loginform";
import Calender from "./components/viewcomponents/Calender/Calender";
import leavecontainer from "./containers/leave/leavecontainer";
import ProcessContainer from "./components/viewcomponents/process/processcomponent";
import ContactContainer from "./components/viewcomponents/contact/contactcomponent";
import TeamContainer from "./components/viewcomponents/team/teamcomponent";
import Home from "./containers/Home";
import ResponsiveDrawer from "./containers/app/menu/app-sidenav";
import LoginFormContainer from "./containers/login/login-form-container";
import TestingCSS from "./containers/testingcss/testingcss";
import UserSettings from "./containers/user/settings/SettingsContainer";
import Terms from "./components/viewcomponents/terms/Terms";
import Privacy from "./components/viewcomponents/terms/Privacy";

// const Home = lazy(() => import('./containers/Home'));
// const ResponsiveDrawer = lazy(() => import('./containers/app/menu/app-sidenav'));
// const LoginFormContainer = lazy(() => import('./containers/login/login-form-container'));
// const TestingCSS = lazy(() => import('./containers/testingcss/testingcss'));
// const UserSettings = lazy(() => import('./containers/user/settings/SettingsContainer'));

class Routes extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/" component={Home} />
              <Route path="/app" component={ResponsiveDrawer} />
              <Route exact path="/login" component={LoginFormContainer} />
              <Route exact path="/testcss" component={TestingCSS} />
              <Route exact path="/profile" component={employeeProfile} />
              <Route exact path="/startsession" component={startsession} />
              <Route exact path="/endsession" component={endsession} />
              <Route exact path="/loginform" component={LoginForm} />
              <Route exact path="/calender" component={Calender} />
              <Route exact path="/leave" component={leavecontainer} />
              <Route exact path="/leave" component={Leaveapplication} />
              <Route exact path="/dashboard" component={dashboard} />
              <Route exact path="/process" component={ProcessContainer} />
              <Route exact path="/contactus" component={ContactContainer} />
              <Route exact path="/team" component={TeamContainer} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={Privacy} />
            </Suspense>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default Routes;
