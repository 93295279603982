import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeCapableComponent from './homepage-capable';

const backgroundShape = require('../../../images/shape.svg');
const gandhi = require('../../../../www/assets/images/gandhi.png');
const webIcon = require('../../../../www/assets/icons/web.png');
const mobileIcon = require('../../../../www/assets/icons/mobile.png');


class HomeWhatWeDoComponent extends Component<any, any> {

  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  state = {
    learnMoredialog: false,
    getStartedDialog: false
  };

  componentDidMount() { }

  openDialog = (event: any) => {
    this.setState({ learnMoredialog: true });
  }

  dialogClose = (event: any) => {
    this.setState({ learnMoredialog: false });
  }

  openGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: true });
  }

  closeGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        {/* <Topbar /> */}
        <div className={classes.root}>

          <div className={classes.whatWeDoRoot}>
            <Grid container direction="row" justify="center">
              <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                <Grid container item xs={12} >

                  <Grid item xs={12} >
                    <div className={classes.sample}>
                      {/* <Typography variant="h3" gutterBottom className={classes.marginbottom72}>
                        Our Services | What we do
                    </Typography> */}

                      <Typography
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false"
                        variant="h3" gutterBottom className={classes.whatWeDoHeader}>
                        Our Services | What we do
                    </Typography>

                      <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false"
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto', height: '6px', width: '400px', backgroundColor: '#b49b57', marginBottom: '30px', borderRadius: 4
                        }}></div>
                    </div>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </div>

          <Grid container direction="row" justify="center">
            <Grid spacing={0} alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12} md={4}>
                <div className={classes.paper} style={{ backgroundColor: '#ecf5fe' }}>
                  <div className={classes.box}>
                    <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      Web Apps
                    </Typography>
                    <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'center', marginLeft: 16, marginRight: 16, marginBottom: 50, fontSize: 16 }}>
                      Mobile first Responsive web application, SEO Friendly with great design experience.
                    </Typography>
                    <Link to='/' className={classes.link}>
                      <img width={100} src={webIcon} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper} style={{ backgroundColor: '#ffffff' }}>
                  <div className={classes.box}>
                    <Link to='/' className={classes.link} >
                      <img width={100} src={mobileIcon} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                    <Typography variant="h5" style={{ marginTop: 50, color: 'black', fontWeight: 400 }} gutterBottom>
                      Backend
                    </Typography>
                    <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'center', marginLeft: 16, marginRight: 16, fontSize: 16 }}>
                      We architect app backends using Node, Python, MySql and MongoDB. Node is known for providing great performance and Python API's integrating with ML and AI.
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper} style={{ backgroundColor: '#ecf5fe' }}>
                  <div className={classes.box}>
                    <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      Mobile Apps
                    </Typography>
                    <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'center', marginLeft: 16, marginRight: 16, marginBottom: 50, fontSize: 16 }}>
                      We build Native iOS and Android Mobile apps with React Native, which has proved the more adopted and alternative for Native iOS and Android.
                    </Typography>
                    <Link to='/' className={classes.link}>
                      <img width={100} src={webIcon} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className={classes.paper} style={{ backgroundColor: '#ffffff' }}>
                  <div className={classes.box}>
                    <Link to='/' className={classes.link} >
                      <img width={100} src={mobileIcon} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                    <Typography variant="h5" style={{ marginTop: 50, color: 'black', fontWeight: 400 }} gutterBottom>
                      Open Source
                    </Typography>
                    <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'center', marginLeft: 16, marginRight: 16, fontSize: 16 }}>
                      Angular, React, Javascript, MySQL, Mongo DB, Redis, D3 JS – Your applications are developed using technologies which are open source and has strong developer community as backbone.
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper} style={{ backgroundColor: '#ecf5fe' }}>
                  <div className={classes.box}>
                    <Typography variant="h5" style={{ color: 'black', fontWeight: 400 }} gutterBottom>
                      Desktop Apps
                    </Typography>
                    <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'center', marginLeft: 16, marginRight: 16, marginBottom: 50, fontSize: 16 }}>
                      With Electron we build desktop apps which runs web kind of experience on desktop which is open source and has large community support. Example apps include GitHub Desktop.
                    </Typography>
                    <Link to='/' className={classes.link}>
                      <img width={100} src={webIcon} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.paper} style={{ backgroundColor: '#ffffff' }}>
                  <div className={classes.box}>
                    <Link to='/' className={classes.link} >
                      <img width={100} src={mobileIcon} alt="" />
                      {/* <span className={classes.tagline}>LOGO</span> */}
                    </Link>
                    <Typography variant="h5" style={{ marginTop: 50, color: 'black', fontWeight: 400 }} gutterBottom>
                      Dev Ops
                    </Typography>
                    <Typography variant="title" gutterBottom style={{ marginTop: 30, color: '#2a405a', textAlign: 'center', marginLeft: 16, marginRight: 16, fontSize: 16 }}>
                      Our end goal is to make your application scalable. We use customised Docker Containers and Kubernettes to deploy your app on AWS, Digital Ocean, Azure, VM and any physical server.
                    </Typography>
                  </div>
                </div>
                {/* <div className={classes.alignRight}>
                    <Button onClick={this.openDialog} variant="outlined" className={classes.actionButtom}>
                      Learn more
                    </Button>
                    <Button onClick={this.openGetStartedDialog} color='primary' variant="contained" className={classes.actionButtom}>
                      Dashboard
                    </Button>
                  </div> */}
              </Grid>
            </Grid>
          </Grid>

          <HomeCapableComponent />

        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f9fcff',
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    }
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '75%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    height: 320,
    textAlign: 'center'
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    }
  },
  marginbottom72: {
    marginBottom: 0,
    fontWeight: 600
  },
  boxcontent: {
    width: 420,
    height: 720,
    backgroundColor: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  },
  whatWeDoRoot: {
    marginTop: 80,
    marginBottom: 40,
    [theme.breakpoints.only('xs')]: {
      marginTop: 60,
      marginBottom: 0,
    }
  },
  whatWeDoHeader: {
    color: '#2a405a',
    marginBottom: 30,
    fontFamily: "'Maven Pro', cursive",
    fontSize: '48px',
    fontWeight: 500,
    letterSpacing: '0px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '32px'
    }
  }
});

//export default HomeComponent;

export default withStyles(styles)(HomeWhatWeDoComponent);
