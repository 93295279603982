// import { types, IRedirectUrl, IShowLoader, IShowToast, ICommonState } from './model';
// import Action from '../IAction';
// import Immutable from 'seamless-immutable';
// import { assign } from 'lodash';
// import { pinkTheme } from '../../store';

// const initialState: ICommonState = Immutable<ICommonState>({
//   redirectUrl: '',
//   showLoader: false,
//   showToast: false,
//   message: '',
//   theme: pinkTheme
// });

// export default function common(state = initialState, action: Action<any>) {
//   switch (action.type) {
//     case types.SHOW_TOAST:
//       return assign({}, state, {
//         showToast: action.payload.showToast,
//         message: action.payload.message
//       });
//     case types.SHOW_LOADER:
//       return assign({}, state, {
//         showLoader: action.payload.showLoader
//       });
//     case types.REDIRECT_URL:
//       return assign({}, state, {
//         redirectUrl: action.payload.redirectUrl
//       });
//     case types.UPDATE_THEME:
//       return assign({}, state, {
//         theme: action.payload.theme
//       })
//     default:
//       return state;
//   }
// }

import { types, IRedirectUrl, IShowLoader, IShowToast, ICommonState } from './model';
import Action from '../IAction';
import Immutable from 'seamless-immutable';
import { pinkTheme } from '../../store';

const initialState: ICommonState = Immutable<ICommonState>({
  redirectUrl: '',
  showLoader: false,
  showToast: false,
  message: '',
  theme: pinkTheme
});

export default function common(state = initialState, action: Action<any>): ICommonState {
  switch (action.type) {
    case types.SHOW_TOAST:
      return {
        ...state,
        showToast: action.payload.showToast,
        message: action.payload.message
      };
    case types.SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload.showLoader
      };
    case types.REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.redirectUrl
      };
    case types.UPDATE_THEME:
      return {
        ...state,
        theme: action.payload.theme
      };
    default:
      return state;
  }
}

