import { createMuiTheme } from '@material-ui/core/styles';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { } from './redux/common/reducer';
import { common, form, loginform, topic } from './redux/index';


const logger = createLogger();
const createStoreWithMiddleware = applyMiddleware(thunk, logger)(createStore);
const reducer = combineReducers({
  form,
  common,
  loginform,
  topic
});
export const store = createStoreWithMiddleware(reducer);
//import DataService from './shared/DataService';
//let createStoreWithMiddleware = applyMiddleware(DataService)(createStore)


const blueTheme = createMuiTheme({
  contentHeader: {
    headerColor: 'grey'
  }
});


const redTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#9c27b0'
    },
    primary: {
      main: '#f44336'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Courier', 'Helvetica'].join(',')
  },
  contentHeader: {
    headerColor: 'grey'
  }
});

const pinkTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Montserrat'
    ].join(','),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0d47a1',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#b49b57',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },

  contentHeader: {
    headerColor: 'grey'
  }


});
const purpleTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Montserrat'
    ].join(','),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#9c27b0',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // error: will use the default color
  },
  contentHeader: {
    headerColor: 'grey'
  }
});

export { blueTheme, redTheme, pinkTheme, purpleTheme };
