import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BaseDialog from '../../common/BaseDialog';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import { autoPlay } from '../../common/common-JSFiles/thirdpart-jsfiles';

// const logo = require('../../images/logo.svg');

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'A first label',
    description: "We build Web, Mobile and Desktop App's",
    imgPath:
      'https://images.unsplash.com/photo-1551263640-1c007852f616?auto=format&fit=crop&w=2100&h=250&q=60',
  },
  {
    label: 'A second label',
    description: 'Strategic partnership to build end to end Product.',
    imgPath:
      'https://images.unsplash.com/photo-1516383740770-fbcc5ccbece0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2110&q=80',
  },
  {
    label: 'Other label',
    description: 'We design experiences which makes peoples lives simple.',
    imgPath:
      'https://images.unsplash.com/photo-1504805572947-34fad45aed93?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80',
  },
];


class SwipeDialog extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  state = {
    activeStep: 0
  }

  handleNext = () => {
    this.setState((prevState: any) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState: any) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep: any) => {
    this.setState({ activeStep });
  };

  render() {
    const { classes } = this.props;
    const maxSteps = tutorialSteps.length;
    const { activeStep } = this.state;
    return (

      <div className={classes.container}>
        <div className={classes.gutterBottom}>
          {/* <img width={100} src={logo} alt="" /> */}
        </div>
        <div>
          <AutoPlaySwipeableViews
            axis='x'
            index={activeStep}
            onChangeIndex={this.handleStepChange}
            enableMouseEvents
            interval={8000}

          >
            {tutorialSteps.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <img className={classes.headerImages} src={step.imgPath} alt={step.label} />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            className={classes.mobileStepper}
            nextButton={
              <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                Next
                </Button>
            }
            backButton={
              <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                Back
                </Button>
            }
          />
        </div>
        {/* <div className={classes.stepsContainer}>
          <Typography
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
            data-aos-once="false"
            className={classes.headerDescription}
            gutterBottom>
            {tutorialSteps[activeStep].description}
          </Typography>
        </div> */}

      </div>

    )
  }
}

const styles: any = (theme: any) => ({
  container: {
    //maxWidth: 600,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  stepsContainer: {
    //marginLeft: 72,
    textAlign: 'center',
    marginTop: 20,
    height: 65,
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      marginLeft: 16,
      marginBottom: 14,
    }
  },
  bottomMargin: {
    marginBottom: theme.spacing.unit * 2
  },
  mobileStepper: {
    display: 'flex',
    padding: '8px',
    backgroundColor: '#fafafa',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  headerImages: {
    width: '100%',
    height: 480,
    [theme.breakpoints.only('xs')]: {
      height: 400,
    }
  },
  headerDescription: {
    fontFamily: 'Montserrat',
    fontSize: 40,
    [theme.breakpoints.only('xs')]: {
      fontSize: 28,
    },
    color: '#1565C0'
  }
});


export default withStyles(styles)(SwipeDialog);
