import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from '../../../../www/assets/images/mobile-image.png';

const backgroundShape = require('../../../images/shape.svg');
const gandhi = require('../../../../www/assets/images/gandhi.png')

class HomeCapableComponent extends Component<any, any> {

  constructor(props: any) {
    super(props);
    console.log(this.props);
  }

  state = {
    learnMoredialog: false,
    getStartedDialog: false
  };

  componentDidMount() { }

  openDialog = (event: any) => {
    this.setState({ learnMoredialog: true });
  }

  dialogClose = (event: any) => {
    this.setState({ learnMoredialog: false });
  }

  openGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: true });
  }

  closeGetStartedDialog = (event: any) => {
    this.setState({ getStartedDialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        {/* <Topbar /> */}
        <div className={classes.root}>

          <div >
            <Grid container direction="row" justify="center">
              <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid}>
                <Grid container item xs={12} >

                  <Grid item xs={12} >
                    <div className={classes.sample}>
                      {/* <Typography variant="h3" gutterBottom className={classes.marginbottom72}>
                        What we are capable of building
                    </Typography> */}

                      <Typography
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false"
                        variant="h3" gutterBottom className={classes.capableHeader}>
                        What we are capable of building
                    </Typography>

                      <div
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false"
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto', height: '6px', width: '400px', backgroundColor: '#b49b57', marginBottom: '30px', borderRadius: 4
                        }}></div>
                    </div>
                  </Grid>

                  <Grid item xs={12} >
                    <div className={classes.sample}>
                      <Typography
                        data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-delay="500"
                        data-aos-easing="ease-in-out"
                        data-aos-once="false" variant="h4" gutterBottom className={classes.marginbottom72}>
                        We build applications using technologies which are open source
                        <br />
                        and has strong developer community as backbone.
                    </Typography>
                    </div>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </div>


          <Grid container direction="row" justify="center">
            <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12} md={6}>

              </Grid>
              <Grid item xs={12} md={6}>

              </Grid>
              <Grid
                data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false"
                item
                xs={12}
                md={6}>
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom>
                      UX / UI Design
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      Sketch / Adobe Illustrator / Photoshop / Adobe XD
                      <br />
                      HTML5, CSS, jQuery, BootStrap, Material Design, Foundation
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      User Experince Design
                    </Typography>
                  </div>
                </Paper>
              </Grid>

            </Grid>
          </Grid>



          <Grid container direction="row" justify="center">
            <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12} md={4} data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false">
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom style={{ color: '#2a405a' }}>
                      UI Development ( Angular )
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      MongoDB, MYSql / Express / Angular / Node
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      MEAN Stack
                      </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}
                data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false">
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom style={{ color: '#2a405a' }}>
                      UI Development ( React Js )
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      Mongo DB, MySql / Express / React / Node
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      MERN Stack
                      </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}
                data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false">
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom>
                      Mobile
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      React Native (iOS, Android)
                      <br />
                      Ionic / Angular (iOS, Android)
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      Native Mobile App
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center">
            <Grid spacing={40} alignItems="center" justify="center" container className={classes.grid}>
              <Grid item xs={12} md={6}
                data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false">
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom>
                      Rest API's
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      Node, Python (Flask), DOT NET Core
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      Backend Services
                      </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}
                data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false">
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom>
                      Database
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      MongoDB, MYSql, Microsoft SQL
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      Backend
                      </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}
                data-aos="zoom-in-up"
                data-aos-duration="500"
                data-aos-delay="0"
                data-aos-easing="ease-in-out"
                data-aos-once="false">
                <Paper className={classes.paper}>
                  <div className={classes.box}>
                    <Typography variant="h6" color='secondary' gutterBottom>
                      Devops
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom style={{ color: '#2a405a' }}>
                      Amazon web services, Azure, Docker, Jenkins, CI / CD, Kubernetees
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" color='primary' gutterBottom>
                      Cloud
                      </Typography>
                  </div>
                </Paper>
              </Grid>

            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f9fcff',
    marginTop: 50,
    [theme.breakpoints.only('xs')]: {
      marginTop: 10,
    }
    //overflow: 'hidden',
    //background: `url(${backgroundShape}) no-repeat`,
    //backgroundSize: 'cover',
    //backgroundPosition: '0 400px',
    //paddingBottom: 200
  },
  grid: {
    width: '85%',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)'
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: 10,
    }
  },
  paper: {
    padding: theme.spacing.unit * 3,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit * 2
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing.unit,
    width: 152
  },
  blockCenter: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  block: {
    padding: theme.spacing.unit * 2,
  },
  box: {
    marginBottom: 40,
    height: 140
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10
  },
  buttonBar: {
    display: 'flex'
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noBorder: {
    borderBottomStyle: 'hidden'
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  sample: {
    textAlign: 'center',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    },
    [theme.breakpoints.only('xs')]: {
      marginBottom: 42,
    }
  },
  marginbottom72: {
    marginBottom: 20,
    fontWeight: 100,
    color: '#2a405a',
    fontSize: 28,
    [theme.breakpoints.only('xs')]: {
      fontSize: 28,
    }
    //fontWeight: 600
  },
  boxcontent: {
    width: 420,
    height: 720,
    backgroundColor: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'calc(100% - 80%)',
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'calc(100% - 94%)',
      width: '90%'
    }
  },
  capableHeader: {
    color: '#2a405a',
    marginBottom: 30,
    fontFamily: "'Maven Pro', cursive",
    fontSize: '48px',
    fontWeight: 500,
    letterSpacing: '0px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '32px',
    }
  }
});

//export default HomeComponent;

export default withStyles(styles)(HomeCapableComponent);
