import { CssBaseline } from "@material-ui/core";
import React from "react";

interface TermSection {
  section: string;
  content: string | string[];
}

interface TermsProps {
  // If you have any specific props for this component, you can define them here
}

const Terms: React.FC<TermsProps> = (props) => {
  const data: TermSection[] = [
    {
      section: "Introduction",
      content:
        'These Terms & Conditions govern the use of dfftech\'s application ("the Application") that integrates with Instagram APIs through Facebook for the purpose of obtaining user statistics. By using the Application, you agree to abide by these Terms & Conditions. If you do not agree with any of the provisions outlined below, please refrain from using the Application.',
    },
    {
      section: "Grant of Access",
      content:
        "dfftech grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Application and its functionalities solely for the purpose of obtaining user statistics through Instagram APIs and in compliance with Instagram's Platform Policy, Facebook Graph API Terms of Service, and Developer Agreement.",
    },
    {
      section: "User Consent and Data Collection",
      content: [
        "By using the Application, you represent and warrant that you have obtained all necessary consents and permissions from the users whose Instagram accounts you intend to access through Facebook APIs.",
        "You understand and acknowledge that dfftech will collect and process user data in accordance with the Privacy Policy available on the Application.",
      ],
    },
    {
      section: "User Data and Security",
      content: [
        "You agree to handle all user data obtained through Facebook APIs responsibly and securely.",
        "You will not use, share, or store user data for any purpose other than obtaining user statistics and as explicitly permitted by the user and the applicable laws.",
      ],
    },
    {
      section: "User Statistics and Analytics",
      content: [
        "The statistics and analytics provided by the Application are based on the data available through Facebook APIs and may not be completely accurate or up-to-date.",
        "dfftech does not guarantee the availability, accuracy, or reliability of user statistics obtained through the Application.",
      ],
    },
    {
      section: "Compliance with Laws and Policies",
      content: [
        "You will comply with all applicable laws, regulations, and guidelines while using the Application and obtaining user statistics through Instagram APIs.",
        "You will adhere to Instagram's Terms of Service, Facebook's Terms of Service, and any other related policies.",
      ],
    },
    {
      section: "Intellectual Property",
      content: [
        "All intellectual property rights related to the Application and its content belong to dfftech.",
        "You may not use, copy, modify, or distribute any part of the Application or user statistics without explicit written permission from dfftech.",
      ],
    },
    {
      section: "Indemnification",
      content:
        "You agree to indemnify and hold harmless dfftech and its affiliates from any claims, damages, losses, liabilities, costs, or expenses arising from your use of the Application, your access to user accounts, or any violation of these Terms & Conditions.",
    },
    {
      section: "Termination",
      content:
        "dfftech reserves the right to suspend or terminate your access to the Application at any time, for any reason, without prior notice.",
    },
    {
      section: "Modifications",
      content:
        "dfftech may update these Terms & Conditions from time to time. Any modifications will be effective immediately upon posting on the Application. Your continued use of the Application after any changes will constitute acceptance of such changes.",
    },
    {
      section: "Disclaimer of Warranty",
      content:
        'The Application is provided "as is" without warranties of any kind, whether express or implied. dfftech does not guarantee the accuracy, reliability, or availability of the Application or user statistics.',
    },
    {
      section: "Limitation of Liability",
      content:
        "To the maximum extent permitted by law, dfftech shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the Application or user statistics obtained through Facebook APIs.",
    },
    {
      section: "Governing Law",
      content:
        "These Terms & Conditions shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.",
    },
    {
      section: "Contact Information",
      content:
        "If you have any questions or concerns regarding these Terms & Conditions, you may contact dfftech at arjun-at-dfftech.in.",
    },
  ];

  return (
    <div>
      <div className="container-fluid">
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand" href="https://www.dfftech.in/">
            <img
              src="https://www.dfftech.com/static/media/dfflogo.01604fc8.svg"
              width="140"
              height="80"
              className="d-inline-block align-top"
              alt=""
            />
          </a>
        </nav>
      </div>
      <div
        className="container"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <h1 className="text-center mt-5">Terms and Conditions</h1>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <h4>{item.section}</h4>
            {typeof item.content === "string" ? (
              <p>{item.content}</p>
            ) : (
              item.content.map((paragraph, pIndex) => (
                <p key={pIndex}>{paragraph}</p>
              ))
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Terms;
